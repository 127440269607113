.u-tiny {
  width: 0.625rem;
  height: 0.625rem;
}

.u-small {
  width: 1rem;
  height: 1rem;
}

.u-medium {
  width: 1.5rem;
  height: 1.5rem;
}

.u-large {
  width: 2.5rem;
  height: 2.5rem;
}
