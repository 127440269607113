.u-color-text-ui-primary {
  color: var(--lp-color-text-ui-primary-base);
}

.u-color-text-ui-secondary {
  color: var(--lp-color-text-ui-secondary);
}

.u-color-text-ui-tertiary {
  color: var(--lp-color-text-ui-secondary);
}

.u-white {
  color: var(--lp-color-white-950);
}

.u-red {
  color: var(--lp-color-text-feedback-error);
}

.u-dark-green[data-icon] {
  fill: var(--lp-color-text-feedback-success);
}

.u-danger-red-dark {
  color: var(--lp-color-text-feedback-error);
}

.u-color-bg-ui-secondary {
  background-color: var(--lp-color-bg-ui-secondary) !important;
}
