.u-a-0 {
  opacity: 0;
}

.u-a-10 {
  opacity: 0.1;
}

.u-a-20 {
  opacity: 0.2;
}

.u-a-30 {
  opacity: 0.3;
}

.u-a-40 {
  opacity: 0.4;
}

.u-a-50 {
  opacity: 0.5;
}

.u-a-60 {
  opacity: 0.6;
}

.u-a-70 {
  opacity: 0.7;
}

.u-a-80 {
  opacity: 0.8;
}

.u-a-90 {
  opacity: 0.9;
}

.u-a-100 {
  opacity: 1;
}
