@import 'stylesheets/media-queries.css';

:root {
  --link-box-shadow-focus: 0 0 0 2px var(--lp-color-shadow-interactive-focus);
  --subtle-link-color: var(--lp-color-text-ui-secondary);

  --header-background-color: var(--color-bg-card);

  --page-gutter-width: 0.625rem;
  --layout-sidenav-width: 0;
  --layout-topbar-height: 3rem;
  --layout-topbar-margins: var(--lp-spacing-400);
  --layout-topbar-action-gap: var(--lp-spacing-200);

  --layout-gutter-width: var(--lp-size-20);

  /* ------- STACKING CONTEXTS ------- */
  --stacking-behind-default: -1;
  --stacking-default: auto;
  --stacking-new-context: 0;
  --stacking-above-new-context: 1;

  --stacking-z-index-10: 10; /* don't have a great semantic name but this seems like a common value */

  --stacking-topbar: var(--lp-z-index-300);
  --stacking-app-banner: calc(var(--lp-z-index-100) + 1);
  --stacking-app-sidebar: var(--lp-z-index-200);
  --stacking-app-entity-sidebar: var(--stacking-app-sidebar);

  --stacking-sticky-header: var(--lp-z-index-100);
  --stacking-experiments-sticky-header: var(--lp-z-index-200);
  --stacking-sticky-manager-controls: var(--lp-z-index-200);

  --stacking-context-target-popover: 9997;
  --stacking-context-trigger-list-item: 10000;
  --stacking-modal-background: 10000;
  --stacking-skip-to-content: 10001;

  --stacking-select-popover: 10002;

  --stacking-context-eval-reason: 30000;
  --stacking-overlay: 100000;
  --stacking-commandbar: 3000000000; /* z-index must be a number larger than injected CommandBar z-index */
  --stacking-variation-diamonds: var(--lp-z-index-500);

  /* ------- IA ------- */
  --ia-area-gap: 2px;
  --ia-grid-unit: var(--lp-spacing-300);
}

:root,
[data-theme='default'] {
  --color-bg-card: var(--lp-color-white-950);
  --nav-background-color: var(--lp-color-gray-950);
}

@media (--tablet) {
  :root {
    --page-gutter-width: 2.5rem;
    --layout-sidenav-width: 14.375rem;
    --layout-topbar-margins: var(--lp-spacing-500);
    --layout-topbar-action-gap: var(--lp-spacing-300);
  }
}

[data-theme='dark'] {
  --color-bg-card: var(--lp-color-gray-950);
}
