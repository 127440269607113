.u-resize-none {
  resize: none;
}

.u-resize-both {
  resize: both;
}

.u-resize-v {
  resize: vertical;
}

.u-resize-h {
  resize: horizontal;
}

/* Utility class for hiding password fields to help lastpass offer to generate a password */
.u-invisible-password {
  position: absolute;
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  width: auto;
  top: 0;
  left: 0;
}
