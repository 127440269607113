/*
Flexbox utilities
 */

.u-flex {
  display: flex;
}

.u-flex-auto {
  flex: 1 1 auto;
}

.u-flex-none {
  flex: none;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-flex-row {
  flex-direction: row;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-stretch {
  align-items: stretch;
}

.u-flex-top {
  align-items: flex-start;
}

.u-flex-middle {
  align-items: center;
}

.u-flex-bottom {
  align-items: flex-end;
}

.u-flex-baseline {
  align-items: baseline;
}

.u-flex-start {
  justify-content: flex-start;
  text-align: start;
}

.u-flex-center {
  justify-content: center;
  text-align: center;
}

.u-flex-end {
  justify-content: flex-end;
  text-align: end;
}

.u-flex-around {
  justify-content: space-around;
}

.u-flex-between {
  justify-content: space-between;
}

.u-flex-evenly {
  justify-content: space-evenly;
}

.u-grow-0 {
  flex-grow: 0;
}

.u-grow-half {
  flex-grow: 0.5;
}

.u-grow-1 {
  flex-grow: 1;
}

.u-grow-2 {
  flex-grow: 2;
}

.u-grow-3 {
  flex-grow: 3;
}

.u-shrink-0 {
  flex-shrink: 0;
}

.u-shrink-1 {
  flex-shrink: 1;
}

.u-shrink-2 {
  flex-shrink: 2;
}

.u-shrink-3 {
  flex-shrink: 3;
}

.u-order-1 {
  order: 1;
}

.u-order-2 {
  order: 2;
}

.u-order-3 {
  order: 3;
}

.u-pullleft {
  margin-right: auto;
}

.u-pullright {
  margin-left: auto;
}

.u-pullbottom {
  margin-top: auto;
}

.u-col2 {
  columns: 2;
}

.u-col3 {
  columns: 3;
}

.u-col2 > * {
  break-inside: avoid;
}

.u-col3 > * {
  break-inside: avoid;
}

.u-gap-0 {
  gap: var(--lp-spacing-100);
}

.u-gap-1 {
  gap: var(--lp-spacing-200);
}

.u-gap-2 {
  gap: var(--lp-spacing-300);
}

.u-gap-3 {
  gap: var(--lp-spacing-400);
}

.u-gap-4 {
  gap: var(--lp-spacing-500);
}

.u-gap-5 {
  gap: var(--lp-spacing-600);
}

.u-gap-6 {
  gap: var(--lp-spacing-700);
}

.u-gap-7 {
  gap: var(--lp-spacing-800);
}

.u-gap-8 {
  gap: var(--lp-spacing-900);
}
