/* Based on https://snook.ca/archives/html_and_css/hiding-content-for-accessibility with a few extra overrides to ensure agnostic display */
.u-assistive {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
