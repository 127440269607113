/* font family */
.u-ff-sans {
  font-family: var(--lp-font-family-base);
}

.u-ff-mono {
  font-family: var(--lp-font-family-monospace);
}

/* text align */
.u-tl {
  text-align: left;
}

.u-tr {
  text-align: right;
}

.u-tc {
  text-align: center;
}

/* text transform */
.u-ttc {
  text-transform: capitalize;
}

.u-ttl {
  text-transform: lowercase;
}

.u-ttu {
  text-transform: uppercase;
}

.u-ttn {
  text-transform: none;
}

/* font size */
.u-fs-xs {
  font-size: var(--lp-font-size-100);
}

.u-fs-xs-even {
  font-size: var(--lp-font-size-100);
}

.u-fs-sm {
  font-size: var(--lp-font-size-200);
}

.u-fs-sm-even {
  font-size: var(--lp-font-size-200);
}

.u-fs-md {
  font-size: var(--lp-font-size-300);
}

.u-fs-l {
  font-size: var(--lp-font-size-400);
}

.u-fs-xl {
  font-size: var(--lp-font-size-400);
}

.u-fs-xxl {
  font-size: var(--lp-font-size-600);
}

.u-fs-xxxl {
  font-size: var(--lp-font-size-800);
}

/* font weight */
.u-fw-light {
  font-weight: var(--lp-font-weight-light);
}

.u-fw-regular {
  font-weight: var(--lp-font-weight-regular);
}

.u-fw-medium {
  font-weight: var(--lp-font-weight-medium);
}

.u-fw-semibold {
  font-weight: var(--lp-font-weight-semibold);
}

.u-fw-bold {
  font-weight: var(--lp-font-weight-bold);
}

/* line height */
.u-lh-sm {
  line-height: var(--lp-line-height-200);
}

/* readability */
.u-measure {
  max-width: 30em;
}

.u-mw-50 {
  max-width: 50%;
}

.u-textKern {
  text-rendering: optimizeLegibility;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
}

.u-success,
.u-positive {
  color: var(--lp-color-text-feedback-success);
}

.u-success [data-icon],
.u-positive [data-icon] {
  fill: var(--lp-color-text-feedback-success);
}

.u-error,
.u-negative,
.u-dangerous {
  color: var(--lp-color-text-feedback-error);
}

.u-error [data-icon],
.u-negative [data-icon],
.u-dangerous [data-icon] {
  fill: var(--lp-color-text-feedback-error);
}

.u-warning {
  color: var(--lp-color-brand-yellow-dark);
}

.u-warning[data-icon] {
  fill: var(--lp-color-brand-yellow-dark);
}

.u-strike {
  text-decoration: line-through;
}

.u-underline {
  text-decoration: underline;
}

.u-subtle {
  color: var(--lp-color-text-ui-secondary);
  fill: var(--lp-color-text-ui-secondary);
}

.u-very-subtle {
  color: var(--lp-color-text-ui-secondary);
  fill: var(--lp-color-text-ui-secondary);
}

.u-currentColor {
  color: currentColor;
}

/* text styles */
.u-voice {
  font-style: italic;
  color: var(--lp-color-text-ui-secondary);
}

.u-italic {
  font-style: italic;
}

.u-prose p,
.u-prose .Snippet {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.u-prose p:last-child,
.u-prose .Snippet:last-child {
  margin-bottom: 0;
}

.u-list-lower-alpha {
  list-style-type: lower-alpha;
}

.u-list-none {
  list-style: none;
}

.u-no-wrap {
  white-space: nowrap;
}

:root[data-type-refresh] {
  .u-fs-xs,
  .u-fs-xs-even {
    font: var(--lp-text-small-1-regular);
  }

  .u-fs-sm,
  .u-fs-sm-even {
    font: var(--lp-text-body-2-regular);
  }

  .u-fs-md {
    font: var(--lp-text-body-1-regular);
  }

  .u-fs-l,
  .u-fs-xl {
    font: var(--lp-text-heading-1-medium);
  }

  .u-fs-xxl,
  .u-fs-xxxl {
    font: var(--lp-text-heading-1-semibold);
  }
}
