.u-ws-normal {
  white-space: normal;
}

.u-ws-nowrap {
  white-space: nowrap;
}

.u-ws-pre {
  white-space: pre;
}

.u-wb-normal {
  word-break: normal;
}

.u-wb-break {
  word-break: break-all;
}

.u-wb-word {
  word-break: break-word;
}
