/* Inspired by http://tachyons.io/docs/layout/widths/ */

.u-w1 {
  width: 0.625rem;
}

.u-w2 {
  width: 1.25rem;
}

.u-w3 {
  width: 1.875rem;
}

.u-w4 {
  width: 2.5rem;
}

.u-4-1 {
  width: 5rem;
}

.u-4-2 {
  width: 8.125rem;
}

.u-w5 {
  width: 10rem;
}

.u-w6 {
  width: 20rem;
}

.u-w7 {
  width: 40rem;
}

.u-w-10 {
  width: 10%;
}

.u-w-20 {
  width: 20%;
}

.u-w-25 {
  width: 25%;
}

.u-w-30 {
  width: 30%;
}

.u-w-33 {
  width: 33%;
}

.u-w-40 {
  width: 40%;
}

.u-w-45 {
  width: 45%;
}

.u-w-50 {
  width: 50%;
}

.u-w-60 {
  width: 60%;
}

.u-w-75 {
  width: 75%;
}

.u-w-80 {
  width: 80%;
}

.u-w-100 {
  width: 100%;
}

.u-w-auto {
  width: auto;
}

@media (--mobile) {
  .u-xs-w1 {
    width: 0.625rem;
  }

  .u-xs-w2 {
    width: 1.25rem;
  }

  .u-xs-w3 {
    width: 1.875rem;
  }

  .u-xs-w4 {
    width: 2.5rem;
  }

  .u-xs-w5 {
    width: 10rem;
  }

  .u-xs-w6 {
    width: 20rem;
  }

  .u-xs-w-10 {
    width: 10%;
  }

  .u-xs-w-20 {
    width: 20%;
  }

  .u-xs-w-25 {
    width: 25%;
  }

  .u-xs-w-33 {
    width: 33%;
  }

  .u-xs-w-34 {
    width: 34%;
  }

  .u-xs-w-40 {
    width: 40%;
  }

  .u-xs-w-50 {
    width: 50%;
  }

  .u-xs-w-60 {
    width: 60%;
  }

  .u-xs-w-75 {
    width: 75%;
  }

  .u-xs-w-80 {
    width: 80%;
  }

  .u-xs-w-100 {
    width: 100%;
  }

  .u-xs-w-auto {
    width: auto;
  }
}

@media (--tablet) {
  .u-sm-w1 {
    width: 0.625rem;
  }

  .u-sm-w2 {
    width: 1.25rem;
  }

  .u-sm-w3 {
    width: 1.875rem;
  }

  .u-sm-w4 {
    width: 2.5rem;
  }

  .u-sm-w5 {
    width: 10rem;
  }

  .u-sm-w6 {
    width: 20rem;
  }

  .u-sm-w-10 {
    width: 10%;
  }

  .u-sm-w-20 {
    width: 20%;
  }

  .u-sm-w-25 {
    width: 25%;
  }

  .u-sm-w-33 {
    width: 33%;
  }

  .u-sm-w-34 {
    width: 34%;
  }

  .u-sm-w-40 {
    width: 40%;
  }

  .u-sm-w-50 {
    width: 50%;
  }

  .u-sm-w-60 {
    width: 60%;
  }

  .u-sm-w-75 {
    width: 75%;
  }

  .u-sm-w-80 {
    width: 80%;
  }

  .u-sm-w-100 {
    width: 100%;
  }

  .u-sm-w-auto {
    width: auto;
  }
}

@media (--desktop) {
  .u-md-w1 {
    width: 0.625rem;
  }

  .u-md-w2 {
    width: 1.25rem;
  }

  .u-md-w3 {
    width: 1.875rem;
  }

  .u-md-w4 {
    width: 2.5rem;
  }

  .u-md-w5 {
    width: 10rem;
  }

  .u-md-w6 {
    width: 20rem;
  }

  .u-md-w-10 {
    width: 10%;
  }

  .u-md-w-20 {
    width: 20%;
  }

  .u-md-w-25 {
    width: 25%;
  }

  .u-md-w-33 {
    width: 33%;
  }

  .u-md-w-34 {
    width: 34%;
  }

  .u-md-w-40 {
    width: 40%;
  }

  .u-md-w-50 {
    width: 50%;
  }

  .u-md-w-60 {
    width: 60%;
  }

  .u-md-w-75 {
    width: 75%;
  }

  .u-md-w-80 {
    width: 80%;
  }

  .u-md-w-100 {
    width: 100%;
  }

  .u-md-w-auto {
    width: auto;
  }
}

@media (--wide) {
  .u-lg-w1 {
    width: 0.625rem;
  }

  .u-lg-w2 {
    width: 1.25rem;
  }

  .u-lg-w3 {
    width: 1.875rem;
  }

  .u-lg-w4 {
    width: 2.5rem;
  }

  .u-lg-w5 {
    width: 10rem;
  }

  .u-lg-w6 {
    width: 20rem;
  }

  .u-lg-w-10 {
    width: 10%;
  }

  .u-lg-w-20 {
    width: 20%;
  }

  .u-lg-w-25 {
    width: 25%;
  }

  .u-lg-w-33 {
    width: 33%;
  }

  .u-lg-w-34 {
    width: 34%;
  }

  .u-lg-w-40 {
    width: 40%;
  }

  .u-lg-w-50 {
    width: 50%;
  }

  .u-lg-w-60 {
    width: 60%;
  }

  .u-lg-w-75 {
    width: 75%;
  }

  .u-lg-w-80 {
    width: 80%;
  }

  .u-lg-w-100 {
    width: 100%;
  }

  .u-lg-w-auto {
    width: auto;
  }
}
