@keyframes pulse {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  100% {
    transform: rotate3d(0, 0, 1, 359deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.u-animated {
  animation-duration: 1s;
}

.u-animated.u-infinite {
  animation-iteration-count: infinite;
}

.u-pulse {
  animation-name: pulse;
}

.u-spin {
  animation-name: spin;
}

.u-flash {
  animation-name: flash;
}

.u-fade-in {
  animation-name: fadeIn;
  animation-duration: 0.7s;
}

.u-fade-out {
  animation-name: fadeOut;
  animation-duration: 0.3s;
  opacity: 0;
  height: 0;
}
