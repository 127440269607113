@import 'stylesheets/media-queries.css';

/* Inspired by http://tachyons.io/docs/layout/spacing/ */

.u-mw-100 {
  max-width: 100%;
}

.u-mw1 {
  max-width: 0.625rem;
}

.u-mw2 {
  max-width: 1.25rem;
}

.u-mw3 {
  max-width: 2.5rem;
}

.u-mw4 {
  max-width: 5rem;
}

.u-mw5 {
  max-width: 10rem;
}

.u-mw6 {
  max-width: 20rem;
}

.u-mw7 {
  max-width: 30rem;
}

.u-mw8 {
  max-width: 40rem;
}

.u-mw9 {
  max-width: 60rem;
}

.u-mw-fitcontent {
  max-width: fit-content;
}

@media (--mobile) {
  .u-xs-mw-100 {
    max-width: 100%;
  }

  .u-xs-mw1 {
    max-width: 0.625rem;
  }

  .u-xs-mw2 {
    max-width: 1.25rem;
  }

  .u-xs-mw3 {
    max-width: 2.5rem;
  }

  .u-xs-mw4 {
    max-width: 5rem;
  }

  .u-xs-mw5 {
    max-width: 10rem;
  }

  .u-xs-mw6 {
    max-width: 20rem;
  }

  .u-xs-mw7 {
    max-width: 30rem;
  }

  .u-xs-mw8 {
    max-width: 40rem;
  }

  .u-xs-mw9 {
    max-width: 60rem;
  }
}

@media (--tablet) {
  .u-sm-mw-100 {
    max-width: 100%;
  }

  .u-sm-mw1 {
    max-width: 0.625rem;
  }

  .u-sm-mw2 {
    max-width: 1.25rem;
  }

  .u-sm-mw3 {
    max-width: 2.5rem;
  }

  .u-sm-mw4 {
    max-width: 5rem;
  }

  .u-sm-mw5 {
    max-width: 10rem;
  }

  .u-sm-mw6 {
    max-width: 20rem;
  }

  .u-sm-mw7 {
    max-width: 30rem;
  }

  .u-sm-mw8 {
    max-width: 40rem;
  }

  .u-sm-mw9 {
    max-width: 60rem;
  }
}

@media (--desktop) {
  .u-md-mw-100 {
    max-width: 100%;
  }

  .u-md-mw1 {
    max-width: 0.625rem;
  }

  .u-md-mw2 {
    max-width: 1.25rem;
  }

  .u-md-mw3 {
    max-width: 2.5rem;
  }

  .u-md-mw4 {
    max-width: 5rem;
  }

  .u-md-mw5 {
    max-width: 10rem;
  }

  .u-md-mw6 {
    max-width: 20rem;
  }

  .u-md-mw7 {
    max-width: 30rem;
  }

  .u-md-mw8 {
    max-width: 40rem;
  }

  .u-md-mw9 {
    max-width: 60rem;
  }
}

@media (--wide) {
  .u-lg-mw-100 {
    max-width: 100%;
  }

  .u-lg-mw1 {
    max-width: 0.625rem;
  }

  .u-lg-mw2 {
    max-width: 1.25rem;
  }

  .u-lg-mw3 {
    max-width: 2.5rem;
  }

  .u-lg-mw4 {
    max-width: 5rem;
  }

  .u-lg-mw5 {
    max-width: 10rem;
  }

  .u-lg-mw6 {
    max-width: 20rem;
  }

  .u-lg-mw7 {
    max-width: 30rem;
  }

  .u-lg-mw8 {
    max-width: 40rem;
  }

  .u-lg-mw9 {
    max-width: 60rem;
  }
}
