*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  background-color: var(--lp-color-bg-ui-primary);
  font-variant-ligatures: no-contextual;
}

h1,
h2,
h3,
h4,
h5,
p,
ol,
ul,
blockquote,
figure {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-size: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

/**
 * Suppress the focus outline on links that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex='-1']:focus {
  outline: none;
}

/**
 * Firefox 4+ adds a bit of extra padding to buttons and inputs. Remove it.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Safari has trouble styling search fields properly
 * if these rules are not set directly on the input type.
 */
input[type='search'] {
  box-sizing: border-box;
  -webkit-appearance: textfield;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

/**
 * Note: Rules have been combined to cut down on redundancy.
 * ideally, we add these rules at an upper scope for all buttons
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 * 3. Show the overflow in Edge.
 * 4. Remove the inheritance of text transform in Firefox.
 * 5. Correct the inability to style clickable types in iOS and Safari.
 */
button {
  -webkit-appearance: button; /* 5 */
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  overflow: visible; /* 3 */
  text-transform: none; /* 4 */
}

/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring {
  outline: 0.063em dotted ButtonText;
}

a:active:not([class*='_Button']):not([data-pressed]):not([data-focused]):not([data-disabled]) {
  color: var(--lp-color-text-interactive-active);
  text-decoration: underline;
  outline: 0;
}
