.u-o-auto {
  overflow: auto;
}

.u-o-hidden {
  overflow: hidden;
}

.u-o-scroll {
  overflow: scroll;
}

.u-ow-break {
  word-wrap: break-word; /* IE/Edge support this but not overflow-wrap */
  overflow-wrap: break-word;
}

.u-to-clip {
  text-overflow: clip;
}

.u-to-ellipsis {
  text-overflow: ellipsis;
}
