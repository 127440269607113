@import 'stylesheets/media-queries.css';

.u-d-none {
  display: none;
}

.u-d-block {
  display: block;
}

.u-d-inlineblock {
  display: inline-block;
}

.u-d-inline {
  display: inline;
}

.u-d-grid {
  display: grid;
}

.u-d-grip-gap-s-5 {
  grid-gap: 0.9375rem;
}
@media (--mobile) {
  .u-xs-d-block {
    display: block;
  }

  .u-xs-d-inlineblock {
    display: inline-block;
  }

  .u-xs-d-inline {
    display: inline;
  }
}

@media (--tablet) {
  .u-sm-d-block {
    display: block;
  }

  .u-sm-d-inlineblock {
    display: inline-block;
  }

  .u-sm-d-inline {
    display: inline;
  }
}

@media (--desktop) {
  .u-md-d-block {
    display: block;
  }

  .u-md-d-inlineblock {
    display: inline-block;
  }

  .u-md-d-inline {
    display: inline;
  }
}

@media (--wide) {
  .u-lg-d-block {
    display: block;
  }

  .u-lg-d-inlineblock {
    display: inline-block;
  }

  .u-lg-d-inline {
    display: inline;
  }
}
