/* Inspired by http://tachyons.io/docs/layout/borders/ */
.u-br-100 {
  border-radius: 100%;
}

.u-br-10 {
  border-radius: 10%;
}

.u-border-radius-regular {
  border-radius: var(--lp-border-radius-regular);
}

.u-border-radius-medium {
  border-radius: var(--lp-border-radius-medium);
}

.u-br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.u-br--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.u-br--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.u-br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
