.osmo-button {
  background-image: linear-gradient(
    315deg,
    hsl(275deg 48% 44%) 0%,
    hsl(271deg 48% 47%) 11%,
    hsl(267deg 48% 49%) 22%,
    hsl(263deg 51% 51%) 33%,
    hsl(259deg 57% 54%) 44%,
    hsl(255deg 63% 57%) 56%,
    hsl(250deg 70% 59%) 67%,
    hsl(244deg 79% 62%) 78%,
    hsl(238deg 89% 64%) 89%,
    hsl(232deg 100% 63%) 100%
  );
  border: transparent !important;
}

.osmo-button__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--lp-spacing-300);
}

.osmo-button__icon {
  display: flex;
}

/* this feels harder than it should be… */
.osmo-button__icon svg path {
  display: flex;
  fill: var(--lp-color-white-950);
}

.spacer-200 {
  display: inline-block;
  margin: var(--lp-spacing-200);
}

.spacer-300 {
  display: inline-block;
  margin: var(--lp-spacing-300);
}

.hint {
  color: var(--lp-color-text-ui-secondary);
  font-size: var(--lp-font-size-200);
}

.hint a {
  color: inherit;
}

.empty-state {
  color: var(--lp-color-text-ui-secondary);
}

.truncate-ellipsis {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titlecase {
  text-transform: capitalize;
}

.pull-left {
  margin-inline-end: auto;
}

.pull-right {
  margin-inline-start: auto;
}

[data-line-clamp='2'] {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
