/* global */
@import 'stylesheets/variables.css';
@import 'stylesheets/base.css';
@import '@launchpad-ui/tokens/dist/index.css';
@import '@launchpad-ui/tokens/dist/media-queries.css';
@import '@launchpad-ui/tokens/dist/themes.css';

/* utils */
@import 'stylesheets/utils/debug.css';
@import 'stylesheets/utils/assistive.css';
@import 'stylesheets/utils/text-truncate.css';
@import 'stylesheets/utils/typography.css';
@import 'stylesheets/utils/colors.css';
@import 'stylesheets/utils/align.css';
@import 'stylesheets/utils/animations.css';
@import 'stylesheets/utils/flex.css';
@import 'stylesheets/utils/widths.css';
@import 'stylesheets/utils/max-widths.css';
@import 'stylesheets/utils/max-heights.css';
@import 'stylesheets/utils/heights.css';
@import 'stylesheets/utils/spacing.css';
@import 'stylesheets/utils/borders.css';
@import 'stylesheets/utils/border-radius.css';
@import 'stylesheets/utils/display.css';
@import 'stylesheets/utils/whitespace.css';
@import 'stylesheets/utils/overflow.css';
@import 'stylesheets/utils/opacity.css';
@import 'stylesheets/utils/cursor.css';
@import 'stylesheets/utils/sizes.css';
@import 'stylesheets/utils/form.css';
@import 'stylesheets/utils/ia-poc.css';

html {
  font-size: 100%;
}

body {
  font-family: var(--lp-font-family-base);
  font-size: var(--lp-font-size-300);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-primary-base);
}

h1,
h2,
h3,
h4 {
  font-weight: var(--lp-font-weight-medium);
}

h1,
h2,
h3 {
  color: var(--lp-color-text-ui-primary-base);
}

h1 {
  font-size: var(--lp-font-size-500);
  line-height: var(--lp-line-height-500);
  margin-bottom: 8px;
}

h2 {
  font-size: var(--lp-font-size-400);
  line-height: var(--lp-line-height-400);
}

h3 {
  font-size: var(--lp-font-size-300);
  line-height: var(--lp-line-height-300);
}

h4 {
  font-size: var(--lp-font-size-200);
  font-weight: var(--lp-font-weight-semibold);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-secondary);
}

a {
  color: var(--lp-color-text-interactive-base);
  border-radius: var(--lp-border-radius-regular);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:focus-visible:not([data-focus-visible]):not([data-focused]):not([data-pressed]) {
  outline: none;
  box-shadow: var(--link-box-shadow-focus);
}

a.u-subtle {
  color: var(--subtle-link-color);
  text-decoration: none;
}

a.u-subtle:hover {
  color: var(--lp-color-text-ui-primary-base);
  text-decoration: underline;
}

a[href^='tel:'] {
  text-decoration: none;
}

pre {
  font-family: var(--lp-font-family-monospace);
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
}

code {
  padding: 0.125rem 0.25rem;
  background-color: var(--lp-color-bg-ui-secondary);
  border-radius: 2px;
  border: 1px solid var(--lp-color-border-ui-primary);
  font-family: var(--lp-font-family-monospace);
  font-size: 87.5%;
  color: var(--lp-color-text-code-keyword);
}

[data-theme='dark'] mark {
  color: white;
  background-color: #767200;
}

abbr {
  cursor: help;
  border-bottom: 1px dotted var(--lp-color-border-ui-secondary);
}

kbd {
  display: inline-block;
  padding: 0.3125rem 0.3125rem 0.25rem 0.3125rem;
  font-family: var(--lp-font-family-monospace);
  font-size: var(--lp-font-size-200);
  line-height: 0.625rem;
  color: #555;
  vertical-align: middle;
  background-color: #fcfcfc;
  border: solid 0.0625rem var(--lp-color-border-ui-primary);
  border-radius: 0.375rem;
  box-shadow: inset 0 -0.0625rem 0 #bbb;
}

small {
  font-size: var(--lp-font-size-200);
  font-weight: var(--lp-font-weight-regular);
  line-height: var(--lp-line-height-300);
  color: var(--lp-color-text-ui-secondary);
}

strong,
b {
  font-weight: var(--lp-font-weight-semibold);
}

svg {
  text {
    font-family: var(--lp-font-family-base);
  }
}

html.theme-transition,
html.theme-transition *,
html.theme-transition *::before,
html.theme-transition *::after {
  transition-delay: 0;
  transition-property: background, background-color, border-color;
  transition-duration: 500ms;
  transition-timing-function: ease;
}

del {
  color: var(--lp-color-black-950);
}

.IconSize-xlarge {
  width: 2.8125rem;
  height: 2.8125rem;
}

:root[data-type-refresh] {
  h1 {
    font: var(--lp-text-heading-1-semibold);
  }

  h2 {
    font: var(--lp-text-heading-2-semibold);
  }

  h3 {
    font: var(--lp-text-heading-3-semibold);
  }

  body {
    font: var(--lp-text-body-1-regular);
  }

  small {
    font: var(--lp-text-small-1-regular);
  }

  code {
    font: var(--lp-text-code-1-regular);
  }
}

[data-theme] {
  color: var(--lp-color-text-ui-primary-base);
}
